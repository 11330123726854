import {
  Box,
  Flex,
  Heading,
  Link,
  ListItem,
  Svg,
  Text,
  UnorderedList
} from '@stars-ecom/shared-atoms-ui'
import { WebsiteContext } from '@stars-ecom/shared-organisms-ui/src/context'
import capitalize from 'lodash/capitalize'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'

import { siteFormatter } from '../utils/metaTitleFormatter'

const AllProductsPage = ({ pageContext }) => {
  const { allProductsPage } = pageContext
  const websiteContext = useContext(WebsiteContext)

  return (
    <Box w="full" backgroundColor="#f6f3f1">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {`${siteFormatter(
            process.env.GATSBY_API_WEBSITE
          )}, Retrouvez les produits et la boutique de
          votre télé`}
        </title>
        <meta name="description" content="Tous les produits" />
      </Helmet>
      <Flex
        m="0"
        justify={{ md: 'center' }}
        border={{ md: '3px solid #fff' }}
        p={{ base: '5px 0 0 16px', md: '20px 0 100px' }}>
        <Text
          as="h1"
          fontSize={{ base: '30px', md: '44px' }}
          fontFamily="PT Sans Narrow"
          w={{ base: 'auto', md: '1000px' }}>
          Tous les produits
        </Text>
      </Flex>

      <Flex
        maxW={{ base: 'full', md: '1000px' }}
        m={{ base: '16px 0 75px', md: ' 75px auto 25px' }}
        direction={{ base: 'column', md: 'row' }}>
        {!websiteContext?.isMobile && (
          <>
            <Flex gridGap="10px" flexFlow="column wrap" mr="20px">
              {allProductsPage?.collections
                ?.filter((c) => c.totalProducts > 0)
                .map((productFamily, i) => {
                  if (i % 4 == 0 || i % 4 == 3) {
                    return (
                      <ProductFamilyCard
                        key={`productFamily_${productFamily.id}`}
                        productFamily={productFamily}
                      />
                    )
                  }
                })}
            </Flex>
            <Flex gridGap="10px" flexFlow="column wrap">
              {allProductsPage?.collections
                ?.filter((c) => c.totalProducts > 0)
                .map((productFamily, i) => {
                  if (i % 4 == 1 || i % 4 == 2) {
                    return (
                      <ProductFamilyCard
                        key={`productFamily_${productFamily.id}`}
                        productFamily={productFamily}
                      />
                    )
                  }
                })}
            </Flex>
          </>
        )}
        {websiteContext?.isMobile &&
          allProductsPage?.collections?.map((productFamily) => {
            return (
              <ProductFamilyCard
                key={`productFamily_${productFamily.id}`}
                productFamily={productFamily}
              />
            )
          })}
      </Flex>
    </Box>
  )
}

AllProductsPage.pageType = 'productList'

export default AllProductsPage

const ProductFamilyCard = ({ productFamily }) => {
  const websiteContext = useContext(WebsiteContext)

  return (
    <Box
      padding={{ base: '20px', md: '20px 30px 0' }}
      bg="#fff"
      w={{ md: '490px' }}
      display="inline-block"
      mb="10px">
      <Flex
        borderBottom="1px solid #333"
        pb="20px"
        alignItems={{ base: 'center', md: 'flex-start' }}>
        <Box>
          <Flex
            w="133px"
            h="133px"
            bg={websiteContext.mainColor}
            justifyContent="center"
            alignItems="center">
            <Svg data={productFamily?.picto} />
          </Flex>
        </Box>
        <Heading
          w={{ base: '100%', md: 'auto' }}
          marginLeft="15px"
          color="#333"
          fontFamily="PT Sans Narrow"
          fontSize={{ base: '28px', md: '48px' }}
          lineHeight="1"
          fontWeight="bold"
          textTransform="uppercase"
          _hover={{ textDecoration: 'underline' }}>
          <Link to={productFamily?.path}>{capitalize(productFamily?.name)}</Link>
        </Heading>
      </Flex>
      <Box marginY="20px">
        {productFamily?.collections.map((collection) => (
          <CategoryProducts
            key={`productCategory_${collection.id}`}
            categoryTitle={collection.name}
            categoryCount={collection.totalProducts}
            categoryLink={collection.path}
            itemsData={collection.collections}
          />
        ))}
      </Box>
      <Flex
        justifyContent="flex-end"
        position="relative"
        bottom={{ base: '0', md: '25px' }}
        sx={{
          a: {
            '@media (max-width: 768px)': {
              width: '100%'
            }
          }
        }}>
        <Link to={productFamily?.path}>
          <Flex
            justify="center"
            align="center"
            borderRadius="0"
            bg={websiteContext?.mainColor}
            fontFamily="PT Sans"
            color="#fff"
            fontWeight="normal"
            h={{ base: '48px', md: 'auto' }}
            fontSize={{ base: '16px', md: '14px' }}
            padding="5px 20px"
            _hover={{
              bg: websiteContext?.darkColor
            }}>
            Voir tous les produits
          </Flex>
        </Link>
      </Flex>
    </Box>
  )
}

const CategoryProducts = ({ categoryTitle, categoryCount, categoryLink, itemsData }) => {
  return (
    <Box marginTop="10px">
      <Text
        fontFamily="PT Sans, Arial, Sans-serif"
        fontWeight="bold"
        fontSize="12px"
        color="#333"
        _hover={{ textDecoration: 'underline' }}>
        <Link to={categoryLink}>
          {capitalize(categoryTitle)} ({categoryCount})
        </Link>
      </Text>

      <UnorderedList>
        {itemsData.map((item) => (
          <ProductItem
            key={`collection_${item.id}`}
            href={item?.path}
            text={item?.name}
            count={item.totalProducts}
          />
        ))}
      </UnorderedList>
    </Box>
  )
}

const ProductItem = ({ text, count, href /*, isUndefined = text?.toLowerCase() == 'autres'*/ }) => {
  return (
    <ListItem marginLeft="20px" marginY="2px">
      <Text
        fontFamily="PT Sans, Arial, Sans-serif"
        color="#333"
        fontSize="11px"
        display="flex"
        _hover={{ textDecoration: 'underline' }}>
        <Link to={href}>
          {capitalize(text)} ({count})
        </Link>
      </Text>
    </ListItem>
  )
}
